// Author: Vasilis Neris
// Custom scss for Netsteps
//============================



header.page-header{
  .panel.wrapper{
    background-color:$top__bar__background;
    overflow: hidden;
  }
  .top-bar-wrapper{
    padding: $top__bar__padding;
    .header.links{
      display: none;
    }
    .top-bar-columns{
      @include flex(center,center);
      position: relative;
      .column{
        &.first{
          max-width: 450px;
          text-align: center;
        }
        &.second{
          position: absolute;
          right: 0;
          @include flex(center);

          a{
            color: $top__bar__link__color;
            font-size: $top__bar__font__size--xl;
            @include flex(center);
            margin-right: 2rem;
            &:last-child{
              margin-right: 0;
            }
            span{
              line-height: 0;
              font-size: 2rem;
              margin-right: 1rem;
            }
            &:hover{
              color: $top__bar__link__hover__color;
            }
          }
        }

      }
        .swiper-button-next, .swiper-button-prev{
            transform: scale(.6);
            color:color(white,base);
        }
    }
  }
}


@media screen and (max-width: $screen__s){
  header.page-header{
    .top-bar-wrapper{
      .top-bar-columns{
        .column.first{
          .slick-slider{
            max-width: 90%;
            margin: auto;
          }
        }
      }
    }
  }
}
