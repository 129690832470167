// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================


//
// Below you will find mixings for buttons across the (almost) whole website. Adjust according to your design.
// Try to adjust only the mixins. If its not possible override in your css.
//============================

@mixin addtoCart() {
    background-color: color(primetel, secondary);
    border-radius: 38px;
    border: none;
    text-transform: uppercase;
    span {
        color: color(white, base);
        font-weight: 700;
    }
}

@mixin addtoCartGrid() {
    background-color: color(black, base);
    display: block;
    width: 100%;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    padding: 12px 0;
    span {
        color: color(white, base);
        font-weight: 700;
    }
}


@mixin checkoutButtons() {
    background-color: color(primetel, secondary);
    border: 2px solid transparent;
    border-radius: 24px;
    //text-transform: uppercase;
    transition: all .5s ease-out;
    span {
        color: color(white, base);
        font-weight: 700;
        font-size: 16px;
    }
    &:hover {
        background-color: color(white, base);
        border-color: (primetel, secondary);

        span {
            color: color(primetel, secondary);
        }
    }
    @media screen and (max-width: $screen__xs) {
        span {
            font-size: 1.6rem;
        }
    }
}

@mixin checkoutMinicartButton() {
    text-transform: uppercase;
    span {
        color: color(white, base);
        font-weight: 700;
    }
    &:hover {
        background-color: color(white, base);
        color: color(primetel, secondary);
    }
    @media screen and (max-width: $screen__xs) {
        font-size: 1.3rem;
    }
}

@mixin actionPrimary() {
    padding: 0 15px;
    min-width: 180px;
    min-height: 52px;
    height: auto;
    border: 1px solid color(text, secondary) !important;
    border-radius: 38px;
    line-height: 44px;
    background-color: color(primetel, secondary);
    box-shadow: none !important;
    position: relative;
    transition: all .5s ease-out;

    span {
        position: relative;
        color: color(white, base);
        font-size: 1.4rem;
        font-weight: 600;
    }

    &:hover, &:focus, &:active {
        background-color: transparent;

        span {
            color: color(primetel, secondary);
        }

        &:before {
            transform: scale(1);
        }
    }

    @media all and (min-width: $screen-md-min) {
        line-height: 52px;

        span {
            font-size: 1.6rem;
        }
    }
}

@mixin actionContact() {
    background-color: color(primetel, secondary);
    border: 0;
    border-radius: 38px;
    color: color(white, base);
    text-transform: uppercase;
    display: block;
    width: 100%;
    padding: 10px 0;
    float: none;
    transition: all .5s ease-out;

    span {
        color: color(white, base);
        font-weight: 700;
    }

    &:hover, &:active {
        border: 1px solid color(primetel, secondary);
        background-color: transparent;

        span {
            color: color(primetel, secondary);
        }
    }
}

@mixin cartActionContinue() {
    float: left;
    font-weight: 700;
    //text-transform: uppercase;
    padding-left: 0;
    border: 0;
    padding-right: 0;
    font-size: 1.3rem;
    color: color(text, primary);
    @include flex(center);
    &:before {
        content: '\e617';
        font-family: 'luma-icons';
        margin-right: 1rem;
        font-size: 1.5rem;
        color: color(text, primary);
    }
    &:hover {
        color: color(black, base);
    }
}


@mixin cartActionClear() {
    transition: all .5s ease-out;
    &:before {
        content: '\e604';
        font-family: 'luma-icons';
        margin-right: 1rem;
        font-size: 2rem;
        color: color(primetel, primary);
        transition: all .5s ease-out;
    }
    &:hover {
        background-color: transparent;
        border: 1px solid color(primetel, secondary);

        span {
            color: color(primetel, secondary);
        }

        &:before {
            color: color(primetel, secondary);
        }
    }
}

@mixin cartActionUpdate() {
    transition: all .5s ease-out;
    &:before {
        content: '\f021';
        font-family: $fontAwesome;
        margin-right: 1rem;
        font-size: 2rem;
        color: color(primetel, primary);
        transition: all .5s ease-out;
    }
    &:hover {
        background-color: transparent;
        border: 1px solid color(primetel, secondary);

        span {
            color: color(primetel, secondary);
        }

        &:before {
            color: color(primetel, secondary);
        }
    }
}

@mixin actionSearch() {
    position: absolute;
    right: 10px;
    top: 7px;
    display: block;
    font-size: 0;
    background-color: transparent;
    border: 0;
    &:before {
        font-size: 2rem;
        line-height: 28px;
        color: color(white, base);
        content: '\e615';
        font-family: 'luma-icons';
    }
}


@mixin actionLogin() {
    border-radius: 38px;
    width: auto;
    margin: 0 auto;
    padding: 10px 30px;
    line-height: 30px;
}


@mixin actionCreateAccount() {
    border-radius: 38px;
    width: auto;
    margin: 0 auto;
    padding: 10px 30px;
    line-height: 30px;
}


//
// Add to cart button product page
//============================
.box-tocart {
    .action.primary {
        @include addtoCart();
    }
}

//
// Add to cart button grid
//============================
.product-wrapper {
    .action.tocart.primary {
        @include addtoCartGrid();
    }
}

//
// Footer Newsletter
//============================
.action.primary.subscribe {
    @include actionPrimary();
}

//
// Proceed to checkout button
//============================
button.action.primary.checkout {
    @include checkoutButtons();
}

//
// Proceed to checkout button in minicart
//============================
div {
    #top-cart-btn-checkout {
        @include checkoutMinicartButton();
    }
}


//
// Contact us Button
//============================
form#contact-form {
    .primary {
        width: 100%;
    }

    button.primary {
        @include actionContact();
    }
}


//
// Cart actions
//============================
div {
    .cart-container .form-cart .actions.main {
        .action {
            display: inline-block;
            background-color: transparent;
            padding: 12px 25px;
            height: 50px;
            border-radius: 3.8rem;
            box-shadow: none;
            border: 1px solid color(gray, darker);

            &.continue {
                @include cartActionContinue();
            }

            &.clear {
                @include cartActionClear();
            }

            &.update {
                @include cartActionUpdate();
            }
        }
    }
}

//
// Search button action
//============================
form#search_mini_form {
    .action.search {
        @include actionSearch();
    }
}


//
// Cart actions
//============================
form#discount-coupon-form {
    .action.primary {
        @include actionPrimary();
        height: 4rem;
    }
}

//
// Login Button
//============================
div {
    .action.primary.login {
        @include actionLogin();
    }
}

//
// Create an account Button
//============================
.form-create-account {
    .action.submit.primary {
        @include actionCreateAccount();
    }
}


//
// General primary button and secondary
//============================
div {
    .action.primary {
        @include actionPrimary();

        &.primary-medium {
            min-width: 140px;
            min-height: 32px;

            span.icon{
                font-size: 1.4rem;
                font-weight: bold;
                padding-right: 8px;
            }

            span {
                font-size: 1.1rem;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .action.secondary {
        @include actionPrimary();
    }

}
