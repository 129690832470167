// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================



//
// checkboxes
//============================
div{
  input[type="checkbox"]{
    position: absolute;
    opacity: 0;
    & + label{
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    & + label:before {
      content: '';
      display: inline-block;
      vertical-align: text-top;
      margin-right: $checkbox__margin__right;
      background-color: $checkbox__inactive__background;
      @include widthHeight($checkbox__dimensions);
    }
    &:hover + label:before {
      background-color: $checkbox__active__background;
    }
    // Box focus
    &:focus + label:before {
      //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background-color: $checkbox__active__background;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background-color: $checkbox__checkmark__color;
      width: 2px;
      height: 2px;
      box-shadow:
              2px 0 0 $checkbox__checkmark__color,
              4px 0 0 $checkbox__checkmark__color,
              4px -2px 0 $checkbox__checkmark__color,
              4px -4px 0 $checkbox__checkmark__color,
              4px -6px 0 $checkbox__checkmark__color,
              4px -8px 0 $checkbox__checkmark__color;
      transform: rotate(45deg);
    }
  }
}

//
// Radio buttons
//============================
div{
  input[type="radio"]{
    position: absolute;
    opacity: 0;
    & + label{
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    & + label:before {
      content: '';
      margin-right: $radio__margin__right;
      display: inline-block;
      vertical-align: text-top;
      @include widthHeight($radio__dimensions);
      border-radius: 50%;
      background-color: color(gray,light);
    }
    &:hover + label:before {
      background-color: $radio__active__background;
    }
    // Box focus
    &:focus + label:before {
      //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background-color: $radio__active__background;
    }
    &:checked + label:after {
      content: '';
      background-color: $radio__dot__color;
      @include widthHeight(12px);
      border-radius: 50%;
      @include flex(center,center);
      left: 6px;
      top: 6px;
      position: absolute;
    }
  }


}

//
// input fields
//============================
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
select:not([disabled]) {
  height: $input__height;
  border: $input__border;
  border-radius: $input__borderradius;
  font-size: $input__font__size;
  font-weight: $input__font__weight;
  color: $input__text__color;
  @include placeholder {
    font-weight: $input__placeholder__weight;
  }

  &:hover {
    border-color: $input__hover__border;
  }

  &:focus {
    box-shadow: $input__focus__shadow;
  }

  @media screen and (max-width: $screen__xs - 1) {
    @include placeholder {
      font-size: 1rem !important;
    }
  }
}
