/* Author: Vasilis Neris
   All variables "usp__" are in variables/_usp.scss
*/

#usps-wrapper{
  margin: 5rem 0;
  .usp{
    @include flex(center,center);
    .icon-wrapper{
      margin-right: 2rem;
      span{
        font-size: $usp__icon__size--l;
        color: $usp__icon__color;
        line-height: 0;
        vertical-align: middle;
      }
    }
    .content-wrapper{
      h4,strong{
        color:$usp__text__color;
        font-size: $usp__content__size--l;
      }
      h4{
        font-weight: 600;
        margin-bottom: .5rem;
        text-transform: uppercase;
        margin-top: 0;
      }
      strong{
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: $screen__l - 33){ // 991px
  #usps-wrapper{
    .usp{
      @include justify-content(flex-start);
      margin-bottom: 3rem;
      &:nth-child(3), &:nth-child(4){
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $screen__m - 1){ // 991px
  #usps-wrapper{
    .usp{
      &:nth-child(3){
        margin-bottom: 3rem;
      }
      .icon-wrapper {
        span{
          font-size: $usp__icon__size--m;
        }
      }
      .content-wrapper{
        h4,strong{
          font-size: $usp__content__size--m;
        }
      }

    }
  }
}