// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================



$root__font__size: 62.5%;  // Defines ratio between root font size and base font size, 1rem = 10px

//
// Headers
//============================
$h1__font__size--base: 2.6rem;
$h2__font__size--base: 2rem;
$h3__font__size--base: 1.8rem;
$h4__font__size--base: 1.6rem;
$h5__font__size--base: 1.5rem;
$h6__font__size--base: 1.4rem;
$heading__margin__bottom: 2rem;
$heading__margin__top: 2rem;



//
// Fonts
//============================
$font_family__main: 'Hero New', 'Open Sans', 'sans-serif';
$font_family__secondary: 'Hero New', 'Open Sans', 'sans-serif';
$fontAwesome: 'FontAwesome';


//
// Colors
//============================
$text__color: color(text, primary);
$border__color: color(text, primary);
$link__color: color(black,base);
$link__hover: color(black,base);


//
// Navigation
//============================
$level0__font__size--base: 1.4rem;
$level1__font__size--base: 1.4rem;
$level2__font__size--base: 1.4rem;








