// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================

.nav-open {
    .nav-toggle {
        left: 0;
        transition: all .3s ease;

        &:before {
            z-index: 10;
            background-color: color(white, base);
            @include flex(center, center);
            position: relative;
            @include widthHeight(54px);
            content: '\e616';
            color: color(text, primary);
        }
    }

    .sections.nav-sections {
        .nav-sections-items {
            .nav-sections-item-title {
                width: 100%;
            }
        }
    }

    .switcher-language {
        position: absolute;
        top: 150%;
        left: 50%;
        width: calc(100% - 50px);
        transform: translateX(-50%);
        text-align: center;

        .language {
            margin-right: 15px;

            &:hover, &:visited {
                color: color(primetel, primary);
            }

            &.current {
                color: color(primetel, secondary);
            }
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    .sections.nav-sections {
        background-color: $sidebar__background;

        ul.mega-menu {
            li {
                width: 100%;
                float: none;
            }
        }

        .section-item-title {
            font-size: $sidebar__title__size;
            float: left;
            width: 50%;
            height: auto;

            &.active {
                background-color: $sidebar__title__background__active;

                a {
                    color: $sidebar__title__color__active;
                }
            }

            a {
                color: $sidebar__title__color;
                display: block;
                width: 100%;
                text-align: center;
                padding: 15px 0;

                &:active {
                    background-color: lighten($sidebar__title__background__active, 15%);
                }
            }

            &[aria-controls="store.links"] {
                display: none;
            }
        }

        .section-item-content {
            margin-top: 71px;
        }

        .header.links {
            .greet.welcome {
                display: none;
            }
        }

    }
}

