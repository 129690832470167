/* Author: Vasilis Neris
   All variables "footer__" are in variables/_footer.scss
*/


footer.page-footer {
    background-color: color(primetel, primary);
    padding-top: 8rem;
    margin-top: 3rem;
    position: relative;
    padding-bottom: 0;

    a, li, p, strong, label, h5 {
        color: #FFF;
    }

    .footer.content {
        border: none;
    }

    .container {
        max-width: $footer__container__maxwidth;
        border-bottom: 1px solid color(black, lighter);
        padding-bottom: 6rem;
    }

    .footer-column {
        h5 {
            font-size: $footer__headers__size--l;
            font-weight: $footer__headers__weight;
            margin-bottom: $footer__headers__margin__bottom;
            text-transform: uppercase;
            margin-top: 0;
        }

        .accordion-toggler {
            display: none;
            color: color(black, base);
        }

        .fa, .fas, .fab {
            position: relative;
            border: 1px solid color(white, base);
            padding: 5px;
            border-radius: 100%;
            width: 45px;
            height: 45px;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        li {
            font-size: $footer__content__size--l;
            font-weight: $footer__content__weight;
            margin-bottom: 1.5rem;

            a {
                text-transform: capitalize;
                color: #666;
                transition: color .3s ease-in-out;

                &:hover {
                    color: color(white, base);
                }
            }

            .icon {
                margin-right: 1rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .social-footer {
            ul {
                @include flex(center, center);

                li {
                    margin-right: 2rem;
                    margin-bottom: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    div#newsletter {
        margin-bottom: 4rem;

        h5 {
            font-size: $footer__newsletter__title__size--l;
            font-weight: $footer__newsletter__title__weight;
            margin-bottom: $footer__headers__margin__bottom;
            text-transform: initial;

            &:after {
                content: none;
            }
        }

        p {
            font-size: $footer__content__size--l;
            margin-bottom: 2rem;
            color: #666;
        }

        label.marketing-label {
            font-size: $footer__terms__size--l;
            font-weight: 600;
            cursor: pointer;

        }

        .terms-validate {
            @include flex(center);
            position: relative;
            margin-bottom: 2rem;

            #marketing-error {
                position: absolute;
                bottom: -20px;
                left: 0;
            }

            .marketing-label{
                margin-bottom: 10px;

                &:before {
                    background-color: transparent;
                    border: 1px solid color(white, base);
                }
            }
        }

        .input-box {
            max-width: 500px;
            @include flex(center);
            position: relative;

            #newsletter-error {
                position: absolute;
                bottom: -20px;
                left: 0;
            }

            input {
                border: none;
                border-bottom: 1px solid color(white, base);
                background-color: transparent;
                color: color(white, base);

                @include placeholder() {
                    color: color(white, base);
                }
            }

            button {
                transition: all .5s ease-in-out;
                margin-left: 8px;
                background-color: transparent;
                border: 1px solid color(white, base) !important;
                height: 58px;

                span {
                    color: color(white, base);
                }

                &:hover, &:visited, &:focus {
                    background-color: color(primetel, secondary);
                    border-color: transparent !important;

                    span {
                        color: color(white, base);
                    }
                }
            }
        }
    }
}

.socials {
    a {
        margin-right: 1rem;
        font-size: $footer__social__icons__size--xl;

        &:last-child {
            margin-right: 0;
        }

        &.facebook:hover {
            color: color(blue, facebook);
        }

        &.twitter:hover {
            color: color(blue, twitter);
        }

        &.instagram:hover {
            color: color(pink, dark);
        }
    }
}

.copyright {
    background-color: color(primetel, primary);

    .container {
        max-width: 90%;

        .copyright-text {
            color: color(white, base);
        }

        a {
            color: #666;
            transition: color .3s ease-in-out;

            &:hover {
                color: color(white, base);
            }
        }

        span {
            color: color(white, base);
        }

        .text-left {
            font-size: $footer__copyright__size--l;
            @include flex(start, initial, column);

            img {
                @include widthHeight(50px);
                object-fit: contain;
            }

            .copyright-links > ul {
                padding: 0;
                list-style: none;
                display: flex;

                & > li {
                    color: color(primetel, third);
                    margin: 10px 25px 0 0;
                }
            }
        }

        .text-right {
            @include flex(center, center, row);

            span {
                font-weight: 500;
                display: inline-block;
                margin-right: 10px;
                font-size: $footer__developby__size--l;
            }

            img {
                width: 130px;
                object-fit: contain;
            }
        }
    }
}


@media screen and (max-width: $screen__xl - 1) {
    footer.page-footer {
        .container {
            max-width: 90%;
        }

        div#newsletter {
            max-width: 90%;
        }
    }
}


@media screen and (max-width: $screen__l - 33) {
    footer.page-footer {
        .container {
            max-width: 100%;
            padding-bottom: 3rem;
        }

        .footer-column {
            margin-bottom: 2rem;
            will-change: margin-bottom;
            @include transition(all .4s ease-in-out);

            h5 {
                margin-bottom: 0;
                padding: 2rem 0;
                border-bottom: 1px solid color(black, white);
                @include flex(center, space-between);
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    right: 25px;
                    background-size: 100%;
                    background: transparent url("../images/icons/plus_icon_white.svg");
                }
            }

            .footer-column-inner {
                ul {
                    max-height: 0;
                    @include visibility(hidden);
                    @include transition(all .4s ease-in-out);
                    will-change: max-height, visibility, opacity;
                }

                .inner-column {
                    &.open {
                        will-change: margin-bottom;
                        margin-bottom: 4rem;
                        @include transition(all .4s ease-in-out);

                        .accordion-toggler {
                            transform: rotate(90deg);
                            @include transition(all .3s ease);
                        }

                        h5 {
                            &:after {
                                background: transparent url("../images/icons/minus_icon_white.svg");
                            }
                        }

                        ul {
                            margin-top: 2rem;
                            max-height: 1000px;
                            visibility: hidden;
                            will-change: max-height, visibility, opacity;
                            @include visibility(visible);
                            @include transition(all .4s ease-in-out);
                        }
                    }
                }
            }

            .accordion-toggler {
                transform: rotate(0);
                @include transition(all .3s ease);

            }

            .social-footer {
                ul {
                    max-height: 500px;
                    @include transition(all .3s ease);
                    @include justify-content(flex-start);
                }
            }

            &.newsletter {
                margin-bottom: 0;
            }

            .accordion-toggler {
                display: block;
            }

            li {
                margin-bottom: 2rem;
            }
        }

        div#newsletter {
            max-width: 60%;
            margin: initial;
            text-align: initial;
            margin: 3rem 0;

            .terms-validate {
                @include justify-content(flex-start);
            }
        }
    }
    .copyright {
        @include flex-direction(column);

        .text-left {
            margin-bottom: 3rem;
        }
    }
}

@media screen and (max-width: $screen__m) {
    .copyright {
        .container {
            .text-left {
                .copyright-text {
                    align-self: center;
                }
                .copyright-links {
                    align-self: center;
                }
            }
        }
    }

    footer.page-footer {
        .footer.content {
            border: 0;
        }

        .footer-column {
            h5 {
                font-size: $footer__headers__size--m;
            }

            li {
                font-size: $footer__content__size--m;
            }
        }

        div#newsletter {
            max-width: 90%;

            h5 {
                font-size: $footer__newsletter__title__size--m;
            }

            p {
                font-size: $footer__content__size--m;
            }

            label.marketing-label {
                font-size: $footer__terms__size--m;
            }
        }

        .copyright {
            .text-right {
                span {
                    font-size: $footer__developby__size--m;
                }
            }
        }
    }
    .footer-logo-wrapper {
        text-align: center;
    }

    .socials {
        text-align: center;

        a {
            font-size: $footer__social__icons__size--m;
        }
    }

}

@media screen and (max-width: $screen__xs - 1) {
    footer.page-footer {
        #newsletter {
            max-width: 100%;
        }
        div#newsletter {
            .input-box {
                flex-direction: column;

                input {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
