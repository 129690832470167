// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================

.customer-navigation {
    .block.block-title {
        display: block;
        text-align: center;

        strong {
            color: color(white, base);
            font-size: 34px;
            font-weight: 600;
        }
    }

    .block.block-search {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #3c4044;
        opacity: 0;
        z-index: -1;
        transition: 0.5s all;
        padding: 3rem 0;
        transform: scale(0);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.search-active {
            transform: scale(1);
            opacity: 1;
            z-index: 100;
            visibility: visible;
        }

        .form.minisearch {
            max-width: 1146px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }

        .field.search {
            .control {
                #search {
                    border: none;
                    border-bottom: .5px solid color(white, base);
                    background-color: transparent;
                    color: color(white, base);

                    @include placeholder {
                        color: color(white, base);
                    }
                }
            }
        }

        &:after {
            content: 'X';
            position: absolute;
            top: 15px;
            right: 15px;
            width: 32px;
            height: 32px;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: color(white, base);
            cursor: pointer;
            opacity: .85;
            transition: opacity .5s;

            &:hover {
                opacity: 1;
            }
        }
    }
}

header.page-header {
    background-color: #ffffff;

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background: color(white, base);
        z-index: 99;
    }

    > .panel.wrapper {
        border: 0;
        overflow: visible;
        display: none;

        //
        // Remove magentos customer links from header but keep them on menu
        //============================
        .panel.header {
            background-color: #F1F0F0;
            padding: 0 60px;
            @include flex(center, space-between);

            .top-link-container {
                ul {
                    @include flex(center, center);
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    > li {
                        margin: 0;
                        padding: 0 24px;

                        a {
                            line-height: 40px;
                            color: color(text, primary);
                            text-decoration: none;
                            display: block;
                        }

                        &:first-child {
                            background-color: #ffffff;
                        }
                    }
                }
            }

            > .action.skip.contentarea, .header.links, &:before, &:after {
                display: none;
            }
        }

        .switcher-language {
            margin: 0;
            min-width: 222px;
            text-align: right;

            .options {
                ul.dropdown {
                    min-width: initial;
                }
            }

            .view-gr, .view-en, .view-el {
                a, span {
                    font-size: 0;

                    &:before {
                        content: '';
                        display: inline-block;
                        @include widthHeight(24px);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: transparent;
                    }
                }
            }

            .view-gr {
                a, span {
                    &:before {
                        background-image: url("../svg/gr.svg");
                    }
                }
            }

            .view-el {
                a, span {
                    &:before {
                        background-image: url("../svg/gr.svg");
                    }
                }
            }

            .view-cy {
                a, span {
                    font-size: 0;

                    &:before {
                        background-image: url("../svg/cy.svg");
                        content: '';
                        display: inline-block;
                        height: 24px;
                        width: 36px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: transparent;
                    }
                }
            }

            .view-en {
                a, span {
                    &:before {
                        background-image: url("../svg/en.svg");
                    }
                }
            }
        }
    }

    .header-top-wrapper {
        .row {
            .customer-support {
                padding: 0;

                span {
                    font-size: 14px;
                    color: color(text, primary);
                    font-weight: 400;

                    &.phone {
                        margin-right: 8px;
                        font-weight: 700;
                    }
                }
            }

            @media all and (max-width: 768px) {
                display: none;
            }
        }
    }

    .inner-wrapper {
        padding: 10px;

        .row {
            justify-content: space-between;
        }

        .column.first {
            padding: 10px 0 10px 25px;

            .logo {
                float: none;
                max-width: initial;
                margin: 0;

                img {
                    max-width: 180px;
                    margin: 0;
                    margin-left: auto;
                }
            }

            .nav-toggle {
                top: 50%;
                transform: translateY(-50%);
                line-height: 30px;

                &:before {
                    top:0;
                    left: 0;
                    color: color(primetel, primary);
                }
            }
        }

        .customer-navigation {
            padding: 10px 25px 10px;

            @include flex(center, flex-end);

            #nav-account {
                padding: 0;
            }

            .icon-span {
                font-size: $header__icons__size--xl;
                color: $header__icons__color;
                line-height: 0;
            }

            .search-promt-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-right: 10px;

                .search-promt {
                    &:hover {
                        color: $header__search__icon__color__hover;
                    }
                }

                .search-title {
                    color: color(text, primary);
                    @media screen and (max-width: $screen-md-max) {
                        display: none;
                    }
                }

                img {
                    filter: brightness(0);
                }
            }

            .customer-wrapper {
                &.logged-in {
                    .header-content-wrapper {
                        cursor: pointer;
                        position: relative;
                    }
                }

                .header-content-wrapper {
                    margin-left: 10px;

                    img {
                        filter: brightness(0);
                    }

                    &.active {
                        div#customer-content-wrapper {
                            display: block;
                        }
                    }

                    @include flex(center, center, column);

                    .icon-span {
                        margin-right: 1rem;
                    }

                    .outer-link {
                        font-weight: $header__customer__outerlinks__weight;
                        color: color(text, primary);

                        &:hover {
                            color: color(text, primary);
                        }

                        &.last {
                            margin-right: 0;
                            border: 0;
                        }
                    }
                }
            }

            div#customer-content-wrapper {
                @include DialogDropdown();
            }

            .minicart-wrapper {
                .cart-icon {
                    img {
                        filter: brightness(0);
                    }
                }

                .action.showcart {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &:before {
                        content: none;
                    }

                    .counter.qty {
                        position: absolute;
                        top: -12px;
                        right: 0;
                        border-radius: 8px;
                        background: rgba(213, 0, 87, 0.8);

                        .counter-number {
                            color: color(white, base);
                        }
                    }
                }

                .text {
                    position: static;
                    width: auto;
                    height: auto;
                    clip: auto;
                    color: color(text, primary);
                    margin-top: 3px;

                    @media screen and (max-width: $screen-md-max) {
                        display: none;
                    }
                }

                .block-minicart {
                    .block-content {

                        .action.viewcart {
                            color: color(primetel, secondary);

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .minicart-items {
                            .product-item-name {
                                a {
                                    color: color(primetel, primary);
                                }
                            }

                            .update-cart-item {
                                opacity: 0;
                                visibility: hidden;
                            }

                            .product-item-details {
                                .details-qty {
                                    .qty-wrapper {
                                        .qty-action {
                                            &.decrease {
                                                margin-right: 5px;
                                            }

                                            &.increase {
                                                margin-left: 5px;
                                            }
                                        }

                                        .cart-item-qty {
                                            border-color: transparent;

                                            &:focus {
                                                border-color: color(primetel, secondary);
                                                box-shadow: 0 0 3px 1px color(primetel, secondary);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

.nav-open {
    header.page-header {
        .inner-wrapper {
            .column.first {
                .nav-toggle {
                    &:before {
                        color: #000;
                    }
                }
            }
        }
    }
}

#minicart-content-wrapper {
    .block-content {
        .actions {
            .secondary {
                display: block;

                .viewcart {
                    display: inline-block;
                    min-height: 52px;
                    line-height: 52px;
                    padding: 5px 25px;
                    background-color: color(primetel, secondary);
                    border: 1px solid transparent;
                    border-radius: 38px;
                    text-decoration: none;
                    margin-bottom: 10px;
                    transition: all .3s ease-in-out;

                    span {
                        color: color(white, base);
                        font-size: 16px;
                    }

                    &:hover {
                        border-color: color(primetel, secondary);
                        background-color: transparent;

                        span {
                            color: color(primetel, secondary);
                        }
                    }
                }
            }
        }

        .minicart-items {
            .product.actions {
                display: flex;
            }
        }
    }
}


@media all and (min-width: $screen-md-min) {
    header.page-header {
        > .panel.wrapper {
            display: block;
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    header.page-header {
        #nav-account {
            //display: none;
            .outer-link {
                display: none;
            }
        }

        .inner-wrapper {
            .row {
                align-items: center;

                .column.first {
                    flex-basis: 50%;
                    max-width: 50%;

                    .logo {
                        position: static;

                        img {
                            max-width: 100px;
                        }
                    }
                }

                .customer-navigation {
                    flex-basis: 50%;
                    max-width: 50%;

                    .icon-span {
                        font-size: $header__icons__size--m;
                    }
                }
            }
        }

        .minicart-wrapper {
            margin: 0;
            margin-left: 2rem;
        }
    }

    #minicart-content-wrapper {
        .block-content {
            .actions {
                .secondary {
                    .viewcart {
                        padding: 5px 10px;
                    }
                }
            }

            .minicart-items {
                .product-item-details {
                    .details-qty {
                        display: flex;
                        align-items: center;
                    }
                }

                .label {
                    margin-right: 5px;
                }

                .item-qty {
                    width: 35px;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-md-min) and (max-width: $screen__l) {
    header.page-header {
        .inner-wrapper {
            .column.first {
                padding: 0 10px;
                flex-basis: 20%;
                max-width: 20%;
                align-self: center;
            }
            .customer-navigation {
                flex-basis: 20%;
                max-width: 20%;
                padding: 0 10px;
                align-self: center;

                .search-promt-wrapper {
                    .search-title {
                        display: none;
                    }
                }

                .header-content-wrapper {
                    .outer-link {
                        display: none;
                    }
                }

                .minicart-wrapper {
                    .text {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    #minicart-content-wrapper {
        .block-content {
            .minicart-items {
                .item {
                    position: relative;
                    padding-top: 40px;

                    .product.actions {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    header.page-header {
        .header.content {
            padding-bottom: 73px;

            .inner-wrapper {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                background-color: #FFF;
                box-shadow: 0 2px 30px rgba(35, 31, 32, 0.06);
                z-index: 15;
            }
        }
    }

    .account {
        .block-collapsible-nav {
            top: 0;
        }
    }

    .nav-open, .filter-active {
        header.page-header {
            .header.content {
                padding-bottom: 0;

                .inner-wrapper {
                    position: static;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen__l) {
    header.page-header {
        .inner-wrapper {
            .column.first {
                .nav-toggle {
                    z-index: 1;
                }
            }
        }
    }

    #minicart-content-wrapper {
        .block-content {
            .minicart-items {
                .item {
                    position: relative;
                    padding-top: 40px;

                    .product.actions {
                        position: absolute;
                        top: 35px;
                        right: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-md-min) {
    .sections.nav-sections {
        margin-bottom: 0;
        background-color: #fff;
        align-self: center;

        .nav-sections-item-content {
            padding: 0;
        }
    }
}

@media screen and (min-width: $screen-sm-min){
    .mega-col .menu-title{
        font-weight: bold;
    }
}

