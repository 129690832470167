// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================



//
// Typography
//============================
$input__font__size: 1.5rem;
$input__font__weight: 600;
$input__placeholder__weight: 500;




//
// Colors
//============================
$checkbox__inactive__background: color(gray,light);
$checkbox__active__background: color(black,base);
$checkbox__checkmark__color: color(white,base);
$radio__inactive__background:color(gray,light);
$radio__active__background:color(black,base);
$radio__dot__color:color(white,base);
$input__text__color: color(text,primary);
$input__placeholder__color: color(text,secondary);
$input__hover__border: color(black,base);
$input__focus__shadow: none;




//
// Others
//============================
//if you tinker with the dimensions you will have to adjust top and left properties to align it again
$checkbox__dimensions: 20px;
$checkbox__margin__right: 1rem;
$radio__dimensions: 24px;
$radio__margin__right: 1rem;
$input__height: 4rem;
$input__border: 1px solid color(gray,base);
$input__borderradius: 0;
