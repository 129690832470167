.product-slider {
    margin-top: 3rem;
    margin-bottom: 3rem;

    & > .title {
        text-align: center;
        display: block;
        font-size: 30px;
        @media all and (min-width: 1025px) {
            font-size: 38px;
        }
    }
}

.product-items {
    .product-item {
        text-align: center;
        background-color: #FFF;
        box-shadow: 0 2px 30px rgba(35, 31, 32, 0.06);
        transition: box-shadow .3s ease-in-out;

        &:hover {
            box-shadow: 0 2px 30px rgba(35, 31, 32, 0.2);
        }

        &.slick-slide {
            margin-left:  15px;
            margin-right:  15px;

            .product-item-info {
                display: flex;
                flex-direction: column;
                height: auto;

                .product-item-details {
                    min-height: 130px;
                }
            }
        }

        .product-item-info {
            padding: 20px;
            width: auto;
            position: relative;
            @include productShadow();

            &:hover {
                border: none;
                margin: 0;
            }

            .product-item-link {
                display: flex;
                text-decoration: none;

                .manufacturer {
                    text-align: left;
                    font-size: 18px;
                    font-weight: 700;
                    @media all and (min-width: 1025px) {
                        font-size: 22px;
                    }
                }

                .product-name {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;

                    .name {
                        font-size: 16px;
                        font-weight: 300;
                        @media all and (min-width: 1025px) {
                            font-size: 22px;
                        }
                    }

                    .storage {
                        font-size: 18px;
                        font-weight: 400;
                        @media all and (min-width: 1025px) {
                            font-size: 22px;
                        }
                    }
                }
            }

            .pricing {
                margin: 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;

                .default {
                    flex-basis: calc(50% - 15px);
                    max-width: calc(50% - 15px);
                    border-right: 1px solid #E0E0E0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;

                    .price-sales {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 3;
                        width: 100px;
                        height: 100px;
                        background-color: rgba(213, 0, 87, 0.9);
                        border: 4px solid #D50057;
                        border-radius: 160px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        font-size: 24px;
                        font-weight: 700;
                        color: #FFF;
                    }

                    .label {
                        display: block;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .price-box {
                        text-align: left;
                        margin: 0;

                        .old-price {
                            .price-container {
                                .price {
                                    text-decoration: line-through;
                                    font-size: 16px;
                                    @media all and (min-width: 1025px) {
                                        font-size: 18px;
                                    }
                                    font-weight: 600;
                                    color: #A7A9AC;
                                }
                            }
                        }

                        .price-container {
                            .price {
                                font-size: 18px;
                                @media all and (min-width: 1025px) {
                                    font-size: 24px;
                                }
                                font-weight: 500;
                                color: #231F20;
                            }
                        }


                        .price-container {
                            .price-label {
                                display: none;
                            }
                        }
                    }
                }

                .advanced {
                    flex-basis: 50%;
                    max-width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                    text-align: left;

                    .upfront, .per-month {
                        color: color(primetel, secondary);
                        font-size: 16px;
                        @media all and (min-width: 1025px) {
                            font-size: 18px;
                        }
                        font-weight: 600;

                        strong {
                            color: color(primetel, secondary);
                            font-size: 18px;
                            @media all and (min-width: 1025px) {
                                font-size: 24px;
                            }
                        }
                    }

                    .extra-info {
                        margin-top: 5px;
                        font-size: 14px;
                        color: #A7A9AC;
                    }
                }
            }

            .product-item-inner {
                display: none;
            }

            .actions-secondary {
                .action.tocompare {
                    display: none;
                }
            }
        }
    }
}

