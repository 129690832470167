@import "mixins";
@import "variables";


.page.messages {
    position: sticky;
    left: 0;
    width: 100%;
    z-index: 10;
    top: 0;
    padding: 0;

    .message {
        padding: 10px 15px;
        margin: 0;

        > div {
            @include flex(center, flex-start);
            @include flex-wrap(wrap);
            color: #ffffff;
            font-size: 1.2rem;
            font-weight: 300;
            padding-right: 20px;

            a {
                color: #ffffff;
                padding: 0 5px;
                text-decoration: underline;
            }

            &:before {
                content: '';
                display: inline-block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-color: transparent;
                margin: 0 5px 0 0;
                position: static;
            }
        }

        &.message-success, &.message-notice {
            background-color: color(primetel, primary);

            > div {
                &:before {
                    background-image: url("../svg/message-success.svg");
                    width: 40px;
                    height: 32px;
                }

                strong {
                    color: #fff;
                    padding: 0 5px;
                }
            }
        }

        &.message-error {
            background-color: #8F0000;

            > div {
                &:before {
                    background-image: url("../svg/message-error.svg");
                    width: 20px;
                    height: 26px;
                }
            }
        }
    }

    button.dismiss-messages {
        padding: 0;
        border: 0;
        box-shadow: none;
        outline: 0;
        @include widthHeight(14px);
        background: transparent url("../svg/message-close.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 0;
        cursor: pointer;
    }

    @media all and (min-width: $screen-md-min) {
        .message {
            padding: 25px 40px;

            > div {
                font-size: 1.8rem;

                &:before {
                    margin-right: 30px;
                }
            }

            &.message-success {
                > div {
                    &:before {
                        width: 40px;
                        height: 32px;
                    }
                }
            }

            &.message-error {
                > div {
                    &:before {
                        width: 37px;
                        height: 32px;
                    }
                }
            }
        }

        button.dismiss-messages {
            right: 40px;
            @include widthHeight(18px);
        }
    }
}
