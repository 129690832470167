/* Author: Vasilis Neris
*/

.stickers{
    position: absolute;
    z-index: 100;
    top:15px;
    left: 0;
    .sticker{
        background-color: color(black,base);
        width: 50px;
        height: 3rem;
        padding:0;
        @include flex(center,center);
        color: color(white,base);
        font-weight: 600;
        margin-bottom: .5rem;
        &.sale{
            background-color: color(red,light);
        }
        &.new{
            // styles new here
        }
    }
}

