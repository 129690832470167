// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================



//
// Typography
//============================





//
// Colors
//============================
$search__overlay__color: transparentize(color(white,base),.3);



//
// Others
//============================
$search__form__maxwidth: 500px;
$search__form__top__offset: 20%;