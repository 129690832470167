// Author: Vasilis Neris
// Custom scss for Netsteps
//============================


//WRITE YOUR MENU SCSS HERE.
//MODULE INSTALLED IS UBERTHEME MENU. THIS MIGHT CHANGE FOR THE THEME IN THE FUTURE!

@media all and (min-width: 1025px) {
    .ub-mega-menu {
        flex: 1 0 auto;
        @include flex(center, center);

        li.mega.dynamic-width {
            .child-content {
                width: 1140px !important;
                left: 50% !important;
                transform: translateX(-50%);
                border: none;
                padding: 0 3rem 50px 3rem;
                background-color: color(white, base);
                box-shadow: 0 8px 16px 0 rgba(#757575, .16), 0 2px 4px 0 rgba(#3b3b3b, 0.04);
            }

            &.mega-hover {
                .group-title {
                    opacity: .8;
                    transform: translateX(0);
                }
            }
        }

        .group-title {
            margin-bottom: 10px;
            opacity: 0;
            transform: translateX(-10px);
            transition: transform 0.5s cubic-bezier(0.3, 0.1, 0.3, 1), opacity 0.7s cubic-bezier(0.3, 0.1, 0.3, 1);

            .menu-title {
                font-size: 13px;
                font-weight: 700;
                color: #191a1b;
            }
        }

        .group-content {
            .menu-title {
                font-size: 13px;
                color: #3c4044;
                font-weight: 400;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: color(primetel, secondary);
                }
            }
        }
    }

    ul.level0 > li.mega {
      & > a.mega {
          background-color: transparent !important;
          color: color(text, primary) !important;
          transition: color 250ms ease-in-out;
          font-weight: 700;
          text-transform: none;

          > span.menu-title {
              background: none;
              padding: 0;
              color: color(text, primary) !important;
              transition: color 250ms ease-in-out;
              font-weight: 700;
              text-transform: none;

              &:hover {
                  color: color(primetel, secondary) !important;
              }
          }

          &.active {
              color: color(primetel, secondary);
          }
      }
    }

    ul.level1 li.mega a.mega {
        color: color(text, primary) !important;

        &:hover {
            color: color(primetel, secondary) !important;
        }
    }

    ul.level0 > li.mega > a.mega.ui-state-active, ul.level0 > li.mega > a.mega:active, ul.level0 > li.mega > a.mega:focus, ul.level0 > li.mega > a.mega {
        &:hover {
            background-color: transparent;

            span {
                color: color(primetel, secondary);
            }
        }
    }

    a.drilldown-back {
        display: none;
    }
}

@media all and (min-width: 576px) and (max-width: 991px) {
    ul.level0 {
        & > li.mega {
            border-bottom: 1px solid #d7d7d7;
            width: 100%;
        }
    }
}

@media all and (max-width: 1025px) {
    .drilldown-sub {
        display: none;
    }

    .child-content.drilldown-root {
        display: block !important;
    }

    .drilldown-root {
        > .drilldown-buttons {
            display: inline-block;
            height: 50px;
            left: 0;
            margin: 0 -20px;
            padding: 0 20px;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            width: 100%;
            z-index: 11;
        }

        &.sticky-disabled {
            > .drilldown-buttons {
                position: static;
            }
        }
    }
    .drilldown {
        &:after {
            content: '';
            height: 50px;
            position: fixed;
            right: -50px;
            top: 0;
            width: 50px;
        }

        &-container {
            li,
            li.mega {
                border-bottom: 0 !important;
                margin-bottom: 0;

                span.mega,
                a.mega {
                    display: block;
                    font-weight: 400;
                    line-height: 48px;
                    width: 100%;
                    color: color(primetel, primary) !important;

                    &:hover {
                        color: color(primetel, secondary) !important;
                    }

                    span.menu-title {
                        background: none !important;
                        display: inline-block !important;
                        padding: 0 !important;
                        text-transform: none;
                        width: auto;
                    }

                    &.has-child {
                        font-weight: 700;
                        position: relative;

                        span.menu-title {
                            //text-transform: uppercase;
                        }
                        &:after {
                            position: absolute;
                            right: 0;
                        }
                    }
                }
            }
        }

        &-root {
            width: 100% !important;

            .drilldown-back {
                display: none;
                font-weight: bold;
                line-height: 48px;
                padding-left: 20px;
                //text-transform: uppercase;
                order: 2;
                position: relative;
                &:active,
                &:focus,
                &:hover {
                    text-decoration: underline;
                }
                &:before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 15px;
                    line-height: inherit;
                    color: inherit;
                    content: '\e617';
                    font-family: 'luma-icons';
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                    position: absolute;
                    left: -4px;
                }
            }

            .menu-group-link {
                display: none;
                float: right;
                font-size: 12px;
                line-height: 48px;
                padding-bottom: 0;
                padding-left: 0;
                text-decoration: underline;
            }
        }

        .mega-col,
        .child-content-inner {
            width: 100% !important;
        }

        .child-content-inner {
            min-height: 0;
            overflow: auto;
        }

        .menu-desc {
            display: none !important;
        }

        .block-title {
            margin: 0.625rem 0 1.25rem;

            strong {
                font-size: 14px;
                font-weight: 700;
            }
        }

        ul.level0 {
            li.mega {
                border-bottom: 0 !important;
                &.has-child {
                    span.mega,
                    a.mega {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        ul.level1,
        ul.level2,
        ul.level3 {
            .child-content {
                border: 0;
                position: static;
                box-shadow: none;
            }

            .child-content-inner {
                padding: 0 0 0 20px;
                box-sizing: border-box;
            }

            li.mega {
                border-bottom: 0 !important;

                span.mega,
                a.mega {
                    span.menu-icon {
                        display: inline-block;
                        height: 15px;
                        left: 0;
                        margin: 0;
                        position: static;
                        top: 0;
                        width: 20px;
                    }
                }
                &.has-child {
                    span.mega,
                    a.mega {
                        &:after {
                            display: none;
                        }
                        span.menu-title {
                            &:after {
                                position: absolute;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-md-min) and (max-width: $screen__l) {
    .ub-mega-menu.drilldown-root {
       & > li.mega {
           &.has-child {
               & > .mega {
                   & > span.menu-title {
                       &:after {
                           content: none;
                       }
                   }
               }
           }

           & > .mega {
               padding: 0 10px;
           }
       }
    }
}

