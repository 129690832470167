body {
    &.checkout-index-index {
        .ampromo-items-add {
            display: none !important;
        }
    }

    .ampromo-items-add {
        box-shadow: 0 2px 30px rgba(35,31,32,0.3);
        border-color: transparent;
        padding: 15px 0;
        font-size: 18px;
        width: 100%;

        @media all and (min-width: 1025px) {
            width: 71%;
        }

        & > a {
            color: color(primetel, primary);
            border-bottom: 1px solid color(primetel, primary);
            font-weight: 600;
        }
    }

    .ampromo-items-content {
        h2 {
            text-align: center;
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 4rem;
        }

        .ampromo-item {
            border: 1px solid transparent;
            box-shadow: 0px 2px 30px rgba(35, 31, 32, 0.06);

            &:hover {
                border-color: #000;
                background-color: transparent;
            }
        }

        .ampromo-item-brand {
            padding-top: 20px;

            .ampromo-description {
                font-weight: 600;
            }
        }

        .ampromo-item-title {
            padding-top: 5px;

            .ampromo-title {
                font-weight: 300;
                text-align: left;
            }
        }

        .ampromo-item-buttons {
            .action {
                &.primary {
                    min-height: 40px;
                    line-height: 40px;
                    background-color: transparent;
                    color: #000;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        right: 2px;
                        bottom: 2px;
                        border-radius: 38px;
                        background-color: #000;
                        transform: scale(0);
                        transition: transform .3s;
                    }

                    span {
                        color: #000;
                    }

                    &:hover {
                        &:before {
                            transform: scale(1);
                        }

                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
