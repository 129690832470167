// Author: Vasilis Neris
// Custom scss for Netsteps
//============================



//
// Typography
//============================
$footer__headers__size--l: 1.6rem;
$footer__headers__size--m: 1.4rem;
$footer__headers__weight: 500;
$footer__content__size--l: 1.6rem;
$footer__content__size--m: 1.4rem;
$footer__content__weight: 400;
$footer__newsletter__title__size--l: 2.8rem;
$footer__newsletter__title__size--m: 2.6rem;
$footer__newsletter__title__weight: 400;
$footer__terms__size--l: 1.2rem;
$footer__terms__size--m: 1.1rem;
$footer__copyright__size--l: 1.2rem;
$footer__copyright__size--m: 1.0rem;
$footer__developby__size--l: 1.2rem;
$footer__developby__size--m: 1.1rem;
$footer__social__icons__size--xl: 3rem;
$footer__social__icons__size--m: 2.2rem;


//
// Colors
//============================
$footer__background: color(white,lighter);


//
// Others
//============================
$footer__container__maxwidth: 80%;
$footer__headers__margin__bottom: 2.5rem;
