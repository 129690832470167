// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================


div {
    .navigation, .breadcrumbs, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
        padding: 0;
        max-width: 100%;
    }
}

header.page-header {
    margin-bottom: 0;
}

.modal-popup.confirm {
    .modal-footer {
        .action-secondary, .action-primary {
            box-shadow: none;
            background-color: transparent;
            padding: 12px 25px;
            border-radius: 3.8rem;
            border-color: color(primetel, primary);
            transition: all .5s ease-out;

            &:hover {
                border-color: color(primetel, secondary);

                span {
                    color: color(primetel, secondary);
                }
            }
        }
    }
}

.aw-rbslider-img-wrapper {
    .aw-rbslider__img {
        filter: none !important;
    }
}

.breadcrumbs{
    background: lighten(color(background,page), 8%);
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    color: color(primetel,primary);
    a{
        font-weight: bold;
        color: color(primetel,primary);
        &:focus,&:active,&:visited,&:hover{
            text-decoration: none;
            color: color(primetel,primary);
        }
    }
}


@media (min-width: 1200px) {
    div {
        .navigation, .breadcrumbs, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
            padding: 0 15px;
        }
    }
    header.page-header {
        //margin-bottom: 20px;
    }
    div {
        .container {
            max-width: 100%;
        }
    }
    .banner-image {
        width: 100%;
        object-fit: fill;
    }
}

@media (max-width: 1024px) {
    div {
        .breadcrumbs {
            padding: 0 15px;
        }
    }
}

.grecaptcha-badge {
    z-index: 1;
    visibility: hidden !important;
}

button:not(.primary) {
    box-shadow: none;
}

