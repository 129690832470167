/* Author: Vasilis Neris
*/



//
// Typography
//============================
$usp__icon__size--l: 5.5rem;
$usp__icon__size--m: 3.5rem;
$usp__content__size--l: 1.2rem;
$usp__content__size--m: 1rem;




//
// Color
//============================
$usp__icon__color: color(black,base);
$usp__text__color: color(text,primary);


//
// Other
//============================
