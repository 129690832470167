body {
    &.cms-home, &.cms-home-en {
        background-color: #E5E5E5;

        .promo-link {
            img {
                width: 100%;
            }
        }

        /* Promo Banners First */
        .section-promo-banners-first  {
            margin-bottom: 3rem;

            .promo-banner {
                padding: 0;
            }
        }

        /* Promo Banners Second */
        .section-promo-banners-second {
            margin-bottom: 3rem;

        }

        /* Promo Banners Third */
        .section-promo-banners-third {
            margin-bottom: 3rem;
        }

        .section-promo-banners {
            padding-left: 0;
            padding-right: 0;
        }

        /* Go International */
        .go-international-section {
            @media all and (min-width: 769px) {
                height: 100%;
            }

            min-height: 600px;
            display: flex;
            flex-direction: column;
            background-color: color(primetel, primary);
            background-repeat: no-repeat;
            background-position: center center;
            background-attachment: fixed;
            background-size: cover;
            margin-left: -15px;
            margin-right: -15px;

            overflow: hidden;

            .section-wrapper {
                margin-top: 80px;

                .title {
                    color: #FFF;
                    text-align: center;
                    font-size: 48px;
                    margin-bottom: 50px;
                }

                .section-inner-wrapper {
                    @media all and (min-width: 769px){
                        flex-direction: row;
                    }

                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;

                    .section-card {
                        @media all and (min-width: 769px){
                            flex-basis: 30%;
                            max-width: 30%;
                            margin-bottom: 0;
                        }

                        flex-basis: 100%;
                        max-width: 100%;
                        min-height: 300px;
                        padding: 15px 20px 25px 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        background-color: rgba(213, 0, 87, 0.8);
                        border-radius: 20px;
                        margin-bottom: 30px;

                        .title {
                            margin: 0;
                            font-size: 24px;
                            text-align: left;
                        }

                        .description {
                            font-size: 18px;
                            color: #FFF;
                        }

                        .button {
                            padding: 15px 20px;
                            border-radius: 48px;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: center;
                            color: #FFF;
                            width: auto;
                            max-width: 200px;
                            border: 1px solid #FFF;
                            transition: background-color .3s ease-in-out;

                            &:hover {
                                background-color: #FFF;
                                color: color(primetel, secondary)
                            }
                        }
                    }
                }
            }
        }

        .check-all-devices {
            margin: 4rem 0;
            text-align: center;

            a.button {
                display: inline-block;
                width: 240px;
                height: 52px;
                text-align: center;
                line-height: 52px;
                background-color: color(primetel, secondary);
                border-radius: 38px;
                font-size: 18px;
                color: #FFF;
                font-weight: 600;
                transition: box-shadow .3s ease-in-out;

                &:hover {
                    box-shadow: 0 2px 30px rgba(35, 31, 32, 0.2);
                }
            }
        }

        /* Promo Banners Last */
        .section-promo-banners-last {
            margin-top: 3rem;
        }
    }
}

@media all and (max-width: $screen-xs-max) {
    body {
        &.cms-home, &.cms-home-en {
            .aw-rbslider-container {
                width: 100% !important;
            }
        }
    }
}
