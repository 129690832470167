// Author: Vasilis Neris
// Custom scss for Netsteps
//============================



//
// Typography
//============================
$product__name__size--l: 2.2rem;
$product__name__size--m: 1.6rem;
$product__name__weight: 300;

$product__price__size--xl: 1.6rem;
$product__price__size--l: 1.5rem;
$product__price__size--m: 1.4rem;

$product__special__price__size--xl: 1.8rem;
$product__special__price__size--l: 1.6rem;
$product__special__price__size--m: 1.5rem;

$product__old__price__size--xl: 1.4rem;
$product__old__price__size--l: 1.4rem;
$product__old__price__size--m: 1.4rem;




//
// Colors
//============================
$product__name__color: color(black,base);
$product__price_color: color(black,base);
$product__special__price_color: color(black,base);
$product__old__price_color: color(grey,base);


//
// Others
//============================


//
// Product Mixins
//============================
@mixin setPriceBox(){
    margin-bottom: 2rem;
    .price-label{
        display: none;
    }
    @include flex(center,center);
    flex-direction: row-reverse;
    .price{
        font-weight: 600;
        font-size: $product__price__size--xl;
        color: $product__price_color;
    }
    .special-price{
        .price{
            font-weight: 600;
            font-size: $product__special__price__size--xl;
            color: $product__special__price_color;
        }
    }
    .old-price{
        margin-right: 2rem;
        .price{
            font-weight: 400;
            font-size: $product__old__price__size--xl;
            color: $product__old__price_color;
            text-decoration: line-through;
        }
    }

}
