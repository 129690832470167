// Author: Vasilis Neris
// Custom scss for Netsteps
//============================



//
// Typography
//============================
$header__customer__outerlinks__size--xl: 1.6rem;
$header__customer__outerlinks__weight:400;
$header__customer__text__transform: uppercase;
$header__icons__size--xl: 2.5rem;
$header__icons__size--m: 2rem;




//
// Colors
//============================
$header__icons__color: color(black,base);
$header__search__icon__color__hover: color(black,base);
$header__customer__outerlinks__color__hover:color(black,base);


//
// Other
//============================

$header__customer__border__seperator: 1px solid color(gray,lighter);




//
// Mixins
//============================
@mixin DialogDropdown(){
  margin: 0;
  padding: 0;
  list-style: none none;
  background: color(white,base);
  border: 1px solid color(gray,lighter);
  margin-top: 4px;
  min-width: 100%;
  width: 320px;
  z-index: 101;
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 100%;
  right: -10px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15);
  &:before,&:after{
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  //This is the carrot pointer
  &:before{
    border: 6px solid;
    border-color: transparent transparent color(black,base) transparent;
    z-index: 99;
    right: 12px;
    top: -12px
  }
  ul{
    list-style: none;
    padding: 25px;
    margin: 0;
    a{
      color: color(text,primary);
      @include animatedUnderline();
    }
  }
}



