.amlocator-main-container{
    .amlocator-button{
        border-color:  color(primetel, secondary) !important;
        color: color(primetel, secondary) !important;
        &:focus,&:active,&:hover{
            background: color(primetel, secondary) !important;
            color: white !important;
        }
    }

    .amlocator-search{
        &:focus,&:active,&:hover{
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNENTAwNTc7c3Ryb2tlLXdpZHRoOjI7fQ0KCS5zdDF7ZmlsbDojRDUwMDU3O30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSI4LjUiIGN5PSI4LjUiIHI9IjcuNSIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTEzLDE1LjFsMi4xLTIuMWw4LjcsOC43bC0yLjEsMi4xTDEzLDE1LjF6Ii8+DQo8L3N2Zz4NCg==") !important;

        }
    }
    .amlocator-current-location .amlocator-text:focus {
        border-color: color(primetel, secondary) !important;
    }

    .amlocator-current-location .amlocator-text:focus + .amlocator-search{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNENTAwNTc7c3Ryb2tlLXdpZHRoOjI7fQ0KCS5zdDF7ZmlsbDojRDUwMDU3O30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSI4LjUiIGN5PSI4LjUiIHI9IjcuNSIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTEzLDE1LjFsMi4xLTIuMWw4LjcsOC43bC0yLjEsMi4xTDEzLDE1LjF6Ii8+DQo8L3N2Zz4NCg==") !important;

    }
    .amlocator-schedule-container .amlocator-today::before{
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRDUwMDU3O30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjU2LDBDMTE0LjgsMCwwLDExNC44LDAsMjU2czExNC44LDI1NiwyNTYsMjU2czI1Ni0xMTQuOCwyNTYtMjU2UzM5Ny4yLDAsMjU2LDB6IE0yNTYsNDU3LjUNCgljLTExMS4xLDAtMjAxLjUtOTAuNC0yMDEuNS0yMDEuNVMxNDQuOSw1NC41LDI1Niw1NC41UzQ1Ny41LDE0NC45LDQ1Ny41LDI1NlMzNjcuMSw0NTcuNSwyNTYsNDU3LjV6Ii8+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzg5LjQsMjQ4LjFoLTExNVYxMDkuOGMwLTExLjYtOS40LTIxLjEtMjEuMS0yMS4xYy0xMS42LDAtMjEuMSw5LjQtMjEuMSwyMS4xdjE1OS40DQoJYzAsMTEuNiw5LjQsMjEuMSwyMS4xLDIxLjFoMTM2LjFjMTEuNiwwLDIxLjEtOS40LDIxLjEtMjEuMVM0MDEuMSwyNDguMSwzODkuNCwyNDguMXoiLz4NCjwvc3ZnPg0K") !important;
    }
}

