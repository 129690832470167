// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================


header.page-header {

    .block-search {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        margin: 0;
        padding-left: 0;
        @include visibility('hidden');
        @include widthHeight(100%);

        &.active {
            @include visibility('visible');
        }

        .block.block-content {
            @include widthHeight(100%);
            @include flex(initial, center);

            input, form {
                max-width: 960px;
                border-color: color(primetel, primary);

                &:focus {
                    border-color: color(primetel, primary);
                    box-shadow: none;
                }
            }

            & > form {
                width: 100%;
                top: $search__form__top__offset;
                position: fixed;

                label {
                    display: none;
                }

                input {
                    position: initial;
                    margin: 0;
                }

            }

            .actions {
                display: none;
            }

            .amsearch-results {
                .amsearch-wrapper-content {
                    .amsearch-item {
                        &.product-item {
                            position: relative;
                            .amsearch-autocomplete-image {
                                .product-image-photo {
                                    border-color: transparent;
                                }
                            }
                            .product-item-details {
                                .manufacturer {
                                    font-size: 16px;
                                    font-weight: 700;
                                    margin-bottom: 10px;
                                }
                                .storage {
                                    margin-left: 8px;
                                    font-weight: 700;
                                }
                                .price-box {
                                    .price-sales {
                                        position: absolute;
                                        top: 5px;
                                        right: 5px;
                                        z-index: 3;
                                        width: 50px;
                                        height: 50px;
                                        background-color: rgba(213, 0, 87, 0.9);
                                        border: 4px solid #D50057;
                                        border-radius: 120px;
                                        box-sizing: border-box;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        text-align: center;
                                        font-size: 14px;
                                        font-weight: 700;
                                        color: #FFF;
                                    }
                                    .old-price {
                                        .price-label {
                                            display: none;
                                        }
                                        .price {
                                            text-decoration: line-through;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

