/* Author: Vasilis Neris
*/
@import "mixins";
@import "category-page/mixins";


//#product-options-wrapper{
//    min-height: 200px;
//}

@media screen and (max-width: 1600px) {
    .slick-track{
        .badges{
            .label{
                height: 115px;
                width: 125px;
                .background{
                    height: 115px;
                    width: 125px;
                }
                span {

                }
            }
        }
    }
}
@media screen and (max-width: 996px ) {
    .slick-track{
        .badges{
            .label{
                height: 100px;
                width: 100px;
                .background{
                    height: 100px;
                    width: 100px;
                }
                span {

                }
            }
        }
    }
}

.badges{
    @media screen and (max-width: 991px) {
        z-index: 2;
    }
    .label{
        height: 145px;
        width: 155px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        top: 7px;
        right: 7px;
        border: 5px solid;
        @media screen and (max-width: $screen__xl) {
            height: 115px;
            width: 125px;
        }
        .background{
            height: 145px;
            width: 155px;
            position: absolute;
            z-index: -1;
            border-radius: 50%;
            top: -5px;
            right: -5px;
            opacity: 0.6;
            @media screen and (max-width: $screen__xl) {
                height: 115px;
                width: 125px;
            }
        }
        span{
            vertical-align: middle;
            text-align: center;
            position: relative;
            display: block;
            width: 100%;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 14px;
            color: #FFFFFF;
            @media screen and (max-width: 1460px) {
                font-size: 12px;
            }
        }
    }
}
.product-wrapper {
    margin-bottom: 3rem;
    position: relative;

    .product-item-info {
        @include productShadow();
        @include widthHeight(100%);
        @include flex(initial, space-between, column);
        border: 0;
        background-color: #ffffff;
        position: relative;
        padding: 15px 15px 10px;


        .netsteps-label {
            position: absolute;
            top: 10px;
            z-index: 3;
            width: 120px;
            height: 120px;
            border-radius: 160px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            &.price-sales {
                color: #FFF;
                background-color: rgba(213, 0, 87, 0.9);
                border: 4px solid #D50057;
                right: 10px;
                font-size: 20px;
                font-weight: 700;
            }

            &.custom-label {
                color: #FFF;
                background-color: rgba(0, 0, 0, 0.9);
                border: 4px solid #000;
                left: 10px;
                font-size: 12px;
                font-weight: 700;
            }
        }

        .product-item-details {
            &.out-of-stock {
                &:before {
                    content: attr(data-label);
                    text-align: center;
                    display: block;
                    font-weight: 700;
                    color: #A7A9AC;
                }
            }
        }
    }

    .product-item-photo {
        text-align: center;
        margin: 0 0 1.6rem;
    }

    .attribute {
        &.manufacturer {
            min-height: 20px;
            font-weight: 700;
            font-size: 1.4rem;
            margin-bottom: 1.2rem;

            a {
                color: color(primetel, primary);
            }
        }
    }

    .product-name-line {
        margin-bottom: 2.8rem;
        text-align: left;
        flex: 1;

        a, .attr-storage {
            color: color(text, primary);
            font-size: $product__name__size--m;
            font-weight: $product__name__weight;
        }

        &.with-storage {
            > a {
                @include flex(center, space-between);
            }
        }

        .attr-storage {
            margin-left: 10px;
        }
    }

    .price-box-container {
        @include priceBox();

        &.with-plan {
            @include priceBoxContainerWithPlan();
        }
    }

    .configurable-attributes {
        padding: 0 5px;
        margin-bottom: 1rem;

        > div {
            @include flex(center, center);
            flex-wrap: wrap;
        }

        .swatch-attribute {
            width: 100%;
            @include flex(center, center);
        }

        .swatch-option {
            border: 0;
            background-color: transparent;
            color: color(black, base);

            &.text {
                line-height: normal;
                @include flex(center, center);
            }

            &.color {
                @include widthHeight(20px);
                border: 1px solid color(grey, lighter);

                &:hover {
                    outline-color: color(grey, lighter);
                    outline-width: 1px;
                }
            }
        }
    }

    .plan-actions {
        text-align: center;

        .action.primary.tocart {
            width: auto;
            min-width: 220px;
            display: inline-block;
            padding: 0 12px;
            border-radius: 3.8rem;
            color: color(white, base);
            border-color: transparent;
            background-color: color(primetel, secondary);
            transition: all .5s ease-out;
            text-transform: none;

            &:hover {
                color: color(primetel, secondary);
                border-color: color(primetel, secondary);
                background-color: transparent;
            }
        }
    }



    @media all and (min-width: $screen-lg-min) {
        .plan-actions {
            position: absolute;
            bottom: 1px;
            left: 0;
            width: 100%;
            transform: translateY(100%);
            background-color: #ffffff;
            padding: 0 20px 20px;
            display: none;
        }

        &:hover {
            z-index: 2;

            .plan-actions {
                display: block;
            }
        }
    }

    @media all and (min-width: $screen-laptop-min) {
        .product-item-photo {
            margin-bottom: 2.2rem;
        }

        .attribute {
            &.manufacturer {
                font-size: 2rem;
                margin-bottom: 1.6rem;
                text-align: left;
            }
        }

        .product-name-line {
            margin-bottom: 5.2rem;

            a, .attr-storage {
                font-size: $product__name__size--l;
            }
        }
    }
}

.price-box {
    position: relative;

    > .loader {
        position: absolute;
        @include widthHeight(100%);
        background: #ffffff url('../images/loader-2.gif') no-repeat center;
    }

    &.loaded {
        > .loader {
            display: none;
        }
    }
}
