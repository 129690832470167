// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================



//
// Typography
//============================
$sidebar__title__size: 1.6rem;
$sidebar__title__weight: 600;


//
// Colors
//============================
$sidebar__background: color(white,base);
$sidebar__title__color: color(text,primary);
$sidebar__title__color__active: color(white,base);
$sidebar__title__background__active: color(primetel, secondary);



//
// Others
//============================
