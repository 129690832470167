// Author: Vasilis Neris
// Custom scss for Netsteps
//============================



//
// Typography
//============================
$top__bar__font__size--xl: 1.4rem;
$top__bar__text__transform: capitalize;
$top__bar__text__weight: 600;



//
// Colors
//============================
$top__bar__background: transparent;
$top__bar__link__color: color(text, primary);
$top__bar__link__hover__color: color(text, secondary);



//
// Others
//============================
$top__bar__padding: 1rem 0;
