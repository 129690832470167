// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewUltraLight.otf") format("opentype");
    font-weight: 100;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewLight.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewRegular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewMedium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewMediumItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewSemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Hero New";
    src: url("../fonts/herofont/HeroNewBold.otf") format("opentype");
    font-weight: 700;
}

@mixin headings($size,$weight: 600) {
    font-size: $size;
    font-weight: $weight;
    margin-top: $heading__margin__top;
    margin-bottom: $heading__margin__bottom;
}


@mixin pageTitle() {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    span {
        color: color(black, base);
    }
}


@mixin links() {
    color: $link__color;
    &:hover {
        color: $link__hover;
        text-decoration: none;
    }
}


html {
    font-size: $root__font__size;
}

div {
    font-family: $font_family__main;
    color: $text__color;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, strong, li, dd, dt, button, input, select, a, span {
        font-family: $font_family__main;
        color: $text__color;
    }
}

div {
    h1, .h1 {
        @include headings($h1__font__size--base);
    }

    h2, .h2 {
        @include headings($h2__font__size--base);
    }

    h3, .h3 {
        @include headings($h3__font__size--base);
    }

    h4, .h4 {
        @include headings($h4__font__size--base);
    }

    h5, .h5 {
        @include headings($h5__font__size--base);
    }

    h6, .h6 {
        @include headings($h6__font__size--base);
    }
}


//
// Page main title
//============================
div {
    .page-title-wrapper {
        .page-title {
            @include pageTitle();
        }
    }
}

//
// General hover for links
//============================
a {
    @include links();
}






