body {
    &.checkout-onepage-success {
        .page-title-wrapper {
            max-width: 1024px;
            width: auto;
            margin-left: auto;
            margin-right: auto;

            .action {
                display: none;
            }
        }

        .checkout-success {
            max-width: 1024px;
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
