@import "../variables";
@import "../mixins";

@mixin priceBoxContainerWithPlan() {
    @include flex(stretch, center);

    > .price-box, > .feature-plan {
        flex: 1;
    }

    @include priceBox();

    .price-box {
        border-right: 1px solid #E0E0E0;
        @include flex(center, space-between, column);

        .retail-label {
            width: auto;
        }

        .old-price {
            order: 1;
            margin-bottom: 0.6rem;
            margin-left: 0;
        }

        .normal-price {
            flex: 1;
            order: 2;
        }
    }

    .feature-plan {
        @include featurePlan();
    }


    @media all and (min-width: $screen-laptop-min) {
        .price-box {
            .old-price {
                margin-bottom: 1rem;
            }
        }
    }
}

@mixin featurePlan() {
    padding: 0 12px;

    .plan-info {
        height: 100%;
        text-align: center;
        @include flex(center, space-between, column);
    }

    .info {
        line-height: 1;
        font-weight: 600;

        span {
            display: inline-block;
            line-height: 1;
        }

        &.upfront {
            font-size: 1.2rem;
        }

        &.per-month {
            font-size: 1.1rem;
            margin: 1rem 0 0.6rem;

            span {
                color: color(text, secondary);

                &:last-child {
                    &:before {
                        content: '/';
                        padding-right: 2px;
                    }
                }
            }

            .amount {
                font-size: 2rem;
            }


        }

        &.plan-name {
            font-size: 1.1rem;

            span {
                color: color(text, third);
            }
        }
    }

    @media all and (min-width: $screen-laptop-min) {
        .info {
            &.upfront {
                font-size: 1.8rem;
            }

            &.per-month {
                font-size: 1.4rem;
                margin: 1.4rem 0 1rem;

                .amount {
                    font-size: 3rem;
                }
            }

            &.plan-name {
                font-size: 1.4rem;
            }
        }
    }
}


@mixin priceBox() {
    .price-box {
        @include flex(flex-end, flex-start);
        @include flex-wrap(wrap);

        span {
            display: inline-block;
            line-height: 1;
        }

        .retail-label {
            font-weight: normal;
            font-size: 1.2rem;
            margin-bottom: 1.3rem;
            width: 100%;
        }

        .price-label, .price-sales {
            display: none !important;
        }

        span.price {
            font-weight: 500;
            font-size: 2rem;
            color: color(text, primary);
        }

        .old-price {
            order: 2;
            margin: 0 0 0 0.6rem;

            span.price {
                color: color(price, old);
                text-decoration: line-through;
                font-size: 1.2rem;
            }
        }

        .normal-price {
            order: 1;
        }
    }

    @media all and (min-width: $screen-laptop-min) {
        .price-box {
            span.price {
                font-size: 3rem;
            }

            .old-price {
                span.price {
                    font-size: 1.8rem;
                }
            }
        }
    }
}
